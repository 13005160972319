<div class="row" style="height:100%; width: 100%; height: 100%; justify-content: stretch; align-items: stretch; ">
  <div class="card" style="padding: 10px; min-width: 250px; justify-content: stretch; align-items: stretch;">
    <div class="column" style="height:100%; flex-grow: 1; justify-self: stretch; ">
      <div style="padding: 5px;  overflow-y:scroll; " >
        <app-nav-node *ngFor="let n of nav" [node]="n" (selected)="onSelectNav($event)"
          style="padding-bottom: 10px; background-color: lightsteelblue; ">
        </app-nav-node>
      </div>
    </div>
  </div>

  <app-form-page class="card" [page]="page" (selected)="onSelect($event)"></app-form-page>

  <app-editor-tab class="card" *ngIf="selected" [element]="selected"></app-editor-tab>

</div>
