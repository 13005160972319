import { Component, Input, OnInit } from '@angular/core';
import { FBase } from '../form';

@Component({
  selector: 'app-form-input-header',
  templateUrl: './form-input-header.component.html',
  styleUrls: ['./form-input-header.component.css']
})
export class FormInputHeaderComponent implements OnInit {
  @Input() element: FBase;

  constructor() { }

  ngOnInit(): void {
  }

}
