import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-page-option',
  templateUrl: './start-page-option.component.html',
  styleUrls: ['./start-page-option.component.css']
})
export class StartPageOptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
