import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FBase, NavBase, NavNode } from '../form';

@Component({
  selector: 'app-nav-node',
  templateUrl: './nav-node.component.html',
  styleUrls: ['./nav-node.component.css']
})
export class NavNodeComponent implements OnInit {
  @Input() node: NavNode;
  @Output() selected = new EventEmitter<FBase>();

  constructor() { }

  ngOnInit(): void {
  }

  select(node: FBase) {
    this.selected.emit(node);
  }

  onSelect(event:MouseEvent) {
    event.stopPropagation();
    this.selected.emit(this.node);
  }

}
