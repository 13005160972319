export class FBase {
  id: string;
  text: string;
  background: string;
  foreground: string;
  icon: string;
  info: string;
  selected: boolean;
}

export class FCommand extends FBase {}

export class FGroup extends FBase {
  children: Array<FBase>;
}

export class FPage extends FGroup {
  commands: Array<FCommand>;
}

export class FSelect extends FBase {
  options: Array<string>;
}

export class FText extends FBase {
}
export class FColumn{
  content: FBase;
  ratio: number;
}
export class FColumns extends FBase {
  columns: Array<FColumn>;
}

export class NavBase extends FBase {

}

export class NavNode extends NavBase {
 child: Array<NavBase>;
}

export class NavPage extends NavBase {
  page: FPage;
}
