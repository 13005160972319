import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FBase, FPage, FText } from "../form";

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.css']
})
export class FormPageComponent implements OnInit {
  @Input() page: FPage;
  @Output() selected = new EventEmitter<FBase>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelected(element: FBase) {
    this.selected.emit(element);
  }
  onSelect2(event: MouseEvent, element: FBase) {
    event.stopPropagation();
    this.selected.emit(element);
  }

  onAddBefore(element: FBase){
    console.info("add before")
    let i = this.page.children.indexOf(element);
    let newItem = new FText();
    this.page.children.splice(i, 0, newItem);
    this.selected.emit(newItem);
  }

  onAddAfter(element: FBase){
    console.info("add after")
    let i = this.page.children.indexOf(element);
    let newItem = new FText();
    newItem.text = "new item";
    this.page.children.splice(i+1, 0, newItem);
    this.selected.emit(newItem);
  }

  onAdd(event: MouseEvent) {
    event.stopPropagation();
    let newItem = new FText();
    newItem.text = "new item";
    newItem.icon = "fa fa-home"

    if(this.page.children)
      this.page.children.push(newItem);
  }

  onDelete(element: FBase){
    console.info("delete")
    let i = this.page.children.indexOf(element);
    this.page.children.splice(i, 1);
    this.selected.emit(null);
  }

}
