import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditorTabComponent } from './editor-tab/editor-tab.component';
import { EditorTabButtonComponent } from './editor-tab-button/editor-tab-button.component';
import { EditorBasicComponent } from './editor-basic/editor-basic.component';
import { EditorOptionsComponent } from './editor-options/editor-options.component';
import { EditorLinksComponent } from './editor-links/editor-links.component';
import { EditorCodingComponent } from './editor-coding/editor-coding.component';
import { EditorOptionsItemComponent } from './editor-options-item/editor-options-item.component';
import { EditorOptionsEditItemComponent } from './editor-options-edit-item/editor-options-edit-item.component';
import { FormPageComponent } from './form-page/form-page.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormTextComponent } from './form-text/form-text.component';
import { NavNodeComponent } from './nav-node/nav-node.component';
import { NavPageComponent } from './nav-page/nav-page.component';
import { FormColumnsComponent } from './form-columns/form-columns.component';
import { FormBaseComponent } from './form-base/form-base.component';
import { FormInputHeaderComponent } from './form-input-header/form-input-header.component';
import { EditorAccessComponent } from './editor-access/editor-access.component';
import { EditorComponent } from './editor/editor.component';
import { StartPageComponent } from './start-page/start-page.component';
import { StartPageOptionComponent } from './start-page-option/start-page-option.component';
import { EditorRuleComponent } from './editor-rule/editor-rule.component';
import { RuleAndComponent } from './rule-and/rule-and.component';
import { RuleValueComponent } from './rule-value/rule-value.component';
import { RuleBaseComponent } from './rule-base/rule-base.component';
import { FormNavComponent } from './form-nav/form-nav.component';

@NgModule({
  declarations: [
    AppComponent,
    EditorTabComponent,
    EditorTabButtonComponent,
    EditorBasicComponent,
    EditorOptionsComponent,
    EditorLinksComponent,
    EditorCodingComponent,
    EditorOptionsItemComponent,
    EditorOptionsEditItemComponent,
    FormPageComponent,
    FormGroupComponent,
    FormSelectComponent,
    FormTextComponent,
    NavNodeComponent,
    NavPageComponent,
    FormColumnsComponent,
    FormBaseComponent,
    FormInputHeaderComponent,
    EditorAccessComponent,
    EditorComponent,
    StartPageComponent,
    StartPageOptionComponent,
    EditorRuleComponent,
    RuleAndComponent,
    RuleValueComponent,
    RuleBaseComponent,
    FormNavComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
