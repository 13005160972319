<div *ngIf="element.text" [(style.background)]="element.background"
  style="display: flex; justify-content: flex-start;">
  <i class="{{element.icon}}" style="margin-right: 5px; font-size:large;  align-self: center;"></i>
  <div>{{element.text}}</div>
</div>
<div (click)="onClick($event)" style="display: flex; flex-direction: row; justify-content: stretch;">
  <app-form-base *ngFor="let i of element.columns"
    (selected)="onSelected($event)"
    [element]="i.content"
    style="flex-grow: 1; margin-right: 10px; margin-left: 10px;">
  </app-form-base>
</div>
