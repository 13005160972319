<div *ngIf="element" [ngClass]="{'element-selected': element.selected}"
(click)="onSelect($event)">
  <div *ngIf="element.selected" class="element-toolbar">
    <div class="element-tools-left">
      <i (click)="onMoveUp($event)" class="fa fa-chevron-up element-tool"></i>
      <i (click)="onMoveDown($event)" class="fa fa-chevron-down element-tool"></i>
    </div>
    <i (click)="onAddBefore($event)" class="fa fa-plus element-tool"></i>
    <div class="element-tools-right">
      <i (click)="onCopy($event)" class="fa fa-copy element-tool"></i>
      <i (click)="onCut($event)" class="fa fa-cut element-tool"></i>
      <i (click)="onDelete($event)" class="fa fa-trash element-tool"></i>
    </div>
  </div>
  <div [ngSwitch]="element.constructor.name" style="background-color: white; border-top-left-radius: 5px;">
    <app-form-group *ngSwitchCase="'FGroup'" [group]="element" (selected)="onSelected($event)"></app-form-group>
    <app-form-select *ngSwitchCase="'FSelect'" [element]="element" (selected)="onSelected($event)"></app-form-select>
    <app-form-text *ngSwitchCase="'FText'" [element]="element" (selected)="onSelected($event)"></app-form-text>
    <app-form-columns *ngSwitchCase="'FColumns'" [element]="element" (selected)="onSelected($event)"></app-form-columns>
  </div>
  <div *ngIf="element.selected" class="element-toolbar" style="justify-content: space-between;"
    (click)="onSelect($event)">
    <div class="element-tools-left">
      <i (click)="onMoveUp($event)" class="fa fa-chevron-up element-tool"></i>
      <i (click)="onMoveDown($event)" class="fa fa-chevron-down element-tool"></i>
    </div>
    <i (click)="onAddAfter($event)" class="fa fa-plus element-tool"></i>
    <div class="element-tools-right">

    </div>
  </div>
