import { Component, Input, OnInit } from '@angular/core';
import { FBase } from '../form';

@Component({
  selector: 'app-editor-basic',
  templateUrl: './editor-basic.component.html',
  styleUrls: ['./editor-basic.component.css']
})
export class EditorBasicComponent implements OnInit {
  @Input() element: FBase;
  constructor() { }

  ngOnInit(): void {
  }

}
