import { Component, Input, OnInit } from '@angular/core';
import { RuleBase } from '../editor-rule/editor-rule.component';

@Component({
  selector: 'app-rule-base',
  templateUrl: './rule-base.component.html',
  styleUrls: ['./rule-base.component.css']
})
export class RuleBaseComponent implements OnInit {

  @Input() element: RuleBase;

  constructor() { }

  ngOnInit(): void {
  }

}
