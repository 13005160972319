import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RuleBase, RuleAnd } from '../editor-rule/editor-rule.component';

@Component({
  selector: 'app-rule-and',
  templateUrl: './rule-and.component.html',
  styleUrls: ['./rule-and.component.css']
})
export class RuleAndComponent implements OnInit {
  @Input() element: RuleAnd;
  @Output() selected = new EventEmitter<RuleBase>();

  constructor() { }

  ngOnInit(): void {
  }

}
