import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FBase, FText } from '../form';

@Component({
  selector: 'app-form-text',
  templateUrl: './form-text.component.html',
  styleUrls: ['./form-text.component.css']
})
export class FormTextComponent implements OnInit {
  @Input() element: FText;
  @Output() selected = new EventEmitter<FBase>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelected(event:MouseEvent, element: FBase) {
    event.stopPropagation();
    this.selected.emit(element);
  }

}
