<div (click)="onSelect($event)" [ngClass]="{'element-selected': node.selected}" style="margin-bottom:5px">
  <div *ngIf="node.selected" class="element-toolbar">
    <div class="element-tools-left">
      <i (click)="onMoveUp($event)" class="fa fa-chevron-up element-tool"></i>
      <i (click)="onMoveDown($event)" class="fa fa-chevron-down element-tool"></i>
    </div>
    <i (click)="onAddBefore($event)" class="fa fa-plus element-tool"></i>
    <div class="element-tools-right">
      <i (click)="onCopy($event)" class="fa fa-copy element-tool"></i>
      <i (click)="onCut($event)" class="fa fa-cut element-tool"></i>
      <i (click)="onDelete($event)" class="fa fa-trash element-tool"></i>
    </div>
  </div>
  <div style="border-top-style: solid;
           border-left-style: solid;
           border-top-left-radius: 5px;
           border-top-color: darkblue;
           border-left-color: darkblue;
           background-color: white;">

    <div class="row" style=" padding: 5px; border-top-left-radius: 4px; justify-content: flex-start;"
      [(style.background)]="node.background">
      <i class="{{node.icon}}" style="margin-right: 5px; font-size:large;  align-self: center;"></i>
      <div>{{node.text}}</div>
    </div>
  </div>
  <div *ngIf="node.selected" class="element-toolbar" style="justify-content: space-between;" (click)="onSelect($event)">
    <div class="element-tools-left">
      <i (click)="onMoveUp($event)" class="fa fa-chevron-up element-tool"></i>
      <i (click)="onMoveDown($event)" class="fa fa-chevron-down element-tool"></i>
    </div>
    <i (click)="onAddAfter($event)" class="fa fa-plus element-tool"></i>
    <div class="element-tools-right">

    </div>

  </div>
