import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-rule',
  templateUrl: './editor-rule.component.html',
  styleUrls: ['./editor-rule.component.css']
})
export class EditorRuleComponent implements OnInit {

  constructor() { }

  rule: RuleAnd;

  ngOnInit(): void {
    this.rule = new RuleAnd();
    this.rule.Item1 = new RuleValue;
    (this.rule.Item1 as RuleValue).Name = "Item1";
    let R2 = new RuleAnd();
    R2.Item1 = new RuleValue();
    (R2.Item1 as RuleValue).Name = "Item 2.1";
    R2.Item2 = new RuleValue();
    (R2.Item2 as RuleValue).Name = "Item 2.1";
    this.rule.Item2  = R2;

  }

}

export class RuleBase {
}

export class RuleValue {
  Name: string;
}

export class RuleAnd extends RuleBase {
  Item1: RuleBase;
  Item2: RuleBase;
}
