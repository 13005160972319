import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FBase } from '../form';

@Component({
  selector: 'app-editor-tab',
  templateUrl: './editor-tab.component.html',
  styleUrls: ['./editor-tab.component.css']
})
export class EditorTabComponent implements OnInit, OnChanges {
  selected: string = "options";

  tabs = [
    { icon: "fa fa-edit", text: "Basic" },
    { icon: "fa fa-th-list", text: "Options", count: "4" },
    { icon: "fa fa-link", text: "Links", count: "17" },
    { icon: "fa fa-code", text: "Encoding", count: "1" },
    { icon: "fa fa-lock", text: "Access", count: "17" },
    { icon: "fa fa-lock", text: "Rule", count: "17" },
  ]

  constructor() {

  }

  private _element: FBase;

  @Input() set element(value: FBase) {
    this._element = value;
    if (this._element) {
      switch (this._element.constructor.name) {
        case "NavNode":
          this.tabs = [
            { icon: "fa fa-edit", text: "Basic", count: "1" }
          ];
          break;
          case "NavPage":
            this.tabs = [
              { icon: "fa fa-edit", text: "Basic", count: "1" }
            ];
            break;
          case "FGroup":
          this.tabs = [
            { icon: "fa fa-edit", text: "Basic", count: "1" },
            { icon: "fa fa-link", text: "Links", count: "17" },
            { icon: "fa fa-code", text: "Encoding", count: "1" }
          ];
          break;
        case "FPage":
          this.tabs = [
            { icon: "fa fa-edit", text: "Basic", count: "1" },
            { icon: "fa fa-link", text: "Links", count: "17" },
            { icon: "fa fa-lock", text: "Access", count: "17" },
            { icon: "fa fa-lock", text: "Rule", count: "17" },
          ];
          break;
        default:
          this.tabs = [
            { icon: "fa fa-edit", text: "Basic", count: "1" },
            { icon: "fa fa-th-list", text: "Options", count: "4" },
            { icon: "fa fa-link", text: "Links", count: "17" },
            { icon: "fa fa-code", text: "Encoding", count: "1" }
          ]

      }
    }
    this.selected = this.tabs[0].text;
  }


  get element(): FBase {
    return this._element;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }



  ngOnInit(): void {
  }

  onSelect(selected: string): void {
    this.selected = selected;
    console.debug(this.selected);
  }

  onClick() {
    console.debug(this.element);
  }


}
