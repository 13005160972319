import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FBase, NavBase } from '../form';

@Component({
  selector: 'app-nav-page',
  templateUrl: './nav-page.component.html',
  styleUrls: ['./nav-page.component.css']
})
export class NavPageComponent implements OnInit {
  @Input() node: NavBase;
  @Output() selected = new EventEmitter<FBase>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(event: MouseEvent) {
    event.stopPropagation();
      this.selected.emit(this.node);
  }
}
