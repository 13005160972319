<table style="width:100%" *ngIf="element">
  <tr>
    <td>Type</td>
    <td>{{element.constructor.name}}</td>
  </tr>
  <tr>
    <td>Id</td>
    <td><input type="text"></td>
  </tr>
  <tr>
    <td>Text</td>
    <td><input type="text" [(ngModel)]="element.text"></td>
  </tr>
  <tr>
    <td>Icon</td>
    <td><input type="text" [(ngModel)]="element.icon"></td>
  </tr>
  <tr>
    <td>Background</td>
    <td><input type="text" [(ngModel)]="element.background"></td>
  </tr>
  <tr>
    <td>Foreground</td>
    <td><input type="text" [(ngModel)]="element.foreground"></td>
  </tr>
</table>
