<div style="display: flex;
flex-direction: row;
border-top-style: solid;
border-color: blue;
margin-left: 2px;">
  <div style="background-color: blue;
  color: white;
  font-weight: 600;
  margin-left: 0px;
  padding: 5px;

  ">&</div>
  <div style="margin-left:3px; display:flex; flex-direction: column ; flex-grow: 1;">
    <div  *ngIf="element">
      <app-rule-base [element]="element.Item1"></app-rule-base>
    </div>
    <div style="border-top-style: solid; border-color: blue;" *ngIf="element">
      <app-rule-base  [element]="element.Item2"></app-rule-base>
    </div>
  </div>
</div>
