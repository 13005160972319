<div (click)="onSelected($event, element)" [(style.background)]="element.background" [(style.color)]="element.foreground">
  <app-form-input-header [element]="element" ></app-form-input-header>

  <div style="display: flex; flex-direction: row; flex-wrap: wrap; ">
    <!-- <div *ngIf="element.options.length >= 3">
    <select>
      <option *ngFor="let c of element.options" value={{c}}>
        <div style="padding: 10px; background-color: lightcoral;">{{c}}</div>
      </option>
    </select>
  </div> -->
    <div *ngFor="let c of element.options" style="display: flex; flex-direction: row; flex-grow: 1;
    margin-left: 5px; margin-bottom: 5px; padding: 5px;
    border-style: solid; border-radius: 5px; border-color: darkblue; border-width: 2px;">
      <i class="fa fa-home" style="margin-right: 5px; font-size:large;  align-self: center;"></i>
      <div style="color: black;">{{c}}</div>
    </div>
  </div>
</div>
