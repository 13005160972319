import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FBase } from '../form';

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.css']
})
export class FormBaseComponent implements OnInit {
  @Input() element: FBase;
  @Input() row: Boolean;
  @Output() selected = new EventEmitter<FBase>();
  @Output() addBefore = new EventEmitter<FBase>();
  @Output() addAfter = new EventEmitter<FBase>();
  @Output() copy = new EventEmitter<FBase>();
  @Output() cut = new EventEmitter<FBase>();
  @Output() paste = new EventEmitter<FBase>();
  @Output() delete = new EventEmitter<FBase>();
  @Output() moveUp = new EventEmitter<FBase>();
  @Output() moveDown = new EventEmitter<FBase>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelected(element: FBase){
    this.selected.emit(element);
  }

  onSelect(event: MouseEvent) {
    event.stopPropagation();
    this.selected.emit(this.element);
  }

  onAddBefore(event: MouseEvent)
  {
    event.stopPropagation();
    this.addBefore.emit(this.element);
  }

  onAddAfter(event: MouseEvent)
  {
    event.stopPropagation();
    this.addAfter.emit(this.element);
  }

  onDelete(event: MouseEvent)
  {
    event.stopPropagation();
    this.delete.emit(this.element);
  }

  onMoveUp(event: MouseEvent) {
    event.stopPropagation();
    this.moveUp.emit(this.element);
  }

  onMoveDown(event: MouseEvent) {
    event.stopPropagation();
    this.moveDown.emit(this.element);
  }
}
