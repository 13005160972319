<table style="width:100%">
  <tr>
    <td>Min options</td>
    <td><input type="text"></td>
  </tr>
  <tr>
    <td>Max options</td>
    <td><input type="text"></td>
  </tr>
</table>

<div style="overflow: auto; height: 200px;">
  <app-editor-options-item></app-editor-options-item>
  <app-editor-options-item></app-editor-options-item>
  <app-editor-options-item></app-editor-options-item>
  <app-editor-options-item></app-editor-options-item>
</div>
<app-editor-options-edit-item></app-editor-options-edit-item>
