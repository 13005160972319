<div class="start-page-option">
<div class="start-page-option-img" style="">
  <!-- <img src="https://picsum.photos/id/237/200/300"
    style="border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0px; " /> -->
</div>
<div class="start-page-option-text">Anonymized viewer</div>
</div>
