import { Component, OnInit } from '@angular/core';
import { FBase, FColumn, FColumns, FCommand, FGroup, FPage, FSelect, FText, NavNode, NavPage } from "./form";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  page: FPage;
  selected: FBase;
  nav: Array<NavNode>;

  title = 'MobiMedStudio';

  ngOnInit(): void {
    this.page = new FPage();
    this.page.text = "A simple page";
    this.page.icon = "fa fa-edit";
    this.page.children = [];
    let gr = new FGroup();
    gr.text = "First section";
    gr.children = [];
    gr.icon = "fa fa-car";
    gr.background = "pink";
    let s = new FSelect();
    s.text = "select 1"
    s.info = "Some more info on this element"
    s.options = [];
    s.options.push("Option 1 äpois");
    s.options.push("Option 2sdsd");
    s.options.push("Option 3 rt");
    s.options.push("Option 4 rg ssr");
    s.options.push("Option 5");
    s.options.push("Option 6");
    gr.children.push(s);
    this.page.children.push(gr);
    let t = new FText();
    t.text = "zxcvbnm,."
    t.icon = "fa fa-user";
    t.info = "wertyuio"
    gr.children.push(t);
    let gr2 = new FGroup();
    gr2.text = "Second section";
    gr2.children = [];
    gr2.background = "lightblue";
    let b = new FBase();
    b.text = "b1"
    b.info = "sdfasdf"
    gr2.children.push(b);
    b = new FBase();
    b.text = "b2"
    gr2.children.push(b);
    gr = new FGroup();
    gr.children = [];
    gr.text = "Section 3";
    gr.background = "turquoise";
    b = new FBase();
    b.text = "b3"
    gr.children.push(b);
    gr2.children.push(s);
    this.page.children.push(gr);
    gr.children.push(gr2);

    let columns = new FColumns();
    columns.columns = [];
    let col = new FColumn();
    col.ratio = 1;
    col.content = new FGroup();
    col.content.text = "123";
    columns.columns.push(col);
    col = new FColumn();
    col.ratio = 1;
    col.content = new FGroup();
    col.content.text = "456";
    columns.columns.push(col);
    //col = new FColumn();
    //col.ratio = 1;

    t = new FText();
    t.text = "qwerty";
    (col.content as FGroup).children = [];
    (col.content as FGroup).children.push(t);
    (col.content as FGroup).children.push(t);
    // col.content.text = "789";
    //columns.columns.push(col);



    this.page.commands = [];
    let c = new FCommand();
    c.text = "c1";
    c.icon  ="fa fa-home";
    this.page.commands.push(c);
    c = new FCommand();
    c.text = "c2";
    c.icon  ="fa fa-car";
    this.page.commands.push(c);
    gr.children.push(columns);


    this.nav = [];
    let n = new NavNode();
    let p: NavPage;
    n.text  ="Node 1"
    n.icon = "fa fa-home";
    n.child = [];
    p = new NavPage();
    p.text = "A simple page";
    p.icon = "fa fa-home";
    p.page = this.page;
    n.child.push(p);
    let n2: NavNode;
    n2 = new NavNode();
    n2.text = "Node 1.2";
    n.child.push(n2);
    n2 = new NavNode();
    n2.text = "Node 1.3";
    n.child.push(n2);
    this.nav.push(n);
    n = new NavNode();
    n.text  ="Node 2"
    this.nav.push(n);
    n = new NavNode();
    n.text  ="Node 3"
    this.nav.push(n);
    n = new NavNode();
    n.text  ="Node 4"
    this.nav.push(n);
  }

  onSelect(element: FBase) {
    console.debug(element);
    this.selected = element;
  }

}
