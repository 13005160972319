<table>
  <tr>
    <th style="text-align: left;">Role</th>
    <th style="padding-right: 5px; padding-left: 5px;">R</th>
    <th style="padding-right: 5px; padding-left: 5px;">W</th>
  </tr>
  <tr>
    <td>Paramedic</td>
    <td style="text-align: center;">x</td>
    <td style="text-align: center;">x</td>
  </tr>
  <tr>
    <td>Nurse</td>
    <td style="text-align: center;">x</td>
    <td style="text-align: center;">x</td>
  </tr>
  <tr>
    <td>Validator</td>
    <td style="text-align: center;">x</td>
    <td style="text-align: center;"></td>
  </tr>
</table>
