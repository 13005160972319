<div (click)="onSelect2($event, page)" class="column"
  style="justify-content: stretch; height: 100%; ">
  <div class="page-header" [(style.background)]="page.background" [(style.color)]="page.foreground">
    <div class="page-header-title">
      <i class="{{page.icon}} space-right"></i>
      <div>{{page.text}}</div>
    </div>
    <div class="page-header-buttons">
      <button *ngFor="let c of page.commands" class="command-button space-left">
        <i class="{{c.icon}} space-right"></i>
        <div>{{c.text}}</div>
      </button>
    </div>
  </div>

  <div style="flex-grow: 1;" class="page-body">
    <div *ngIf="page.children.length == 0" (click)="onAdd($event)" class="element-new">
      <i class="fa fa-plus"></i>
    </div>
    <div style="height: 100px; padding: 5px;">
      <app-form-base *ngFor="let i of page.children" [element]="i" (selected)="onSelected($event)"
        (addBefore)="onAddBefore($event)" (addAfter)="onAddAfter($event)" (delete)="onDelete($event)"></app-form-base>
    </div>
  </div>
</div>
