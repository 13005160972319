import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FBase, FGroup, FPage, FText } from "../form";

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.css']
})
export class FormGroupComponent implements OnInit {
  @Input() group: FGroup;
  @Output() selected = new EventEmitter<FBase>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelected(element: FBase){
    this.selected.emit(element);
  }

  onClick(event: MouseEvent, element: FBase){
    event.stopPropagation();
    this.selected.emit(element);
  }

  onAdd(event: MouseEvent){
    event.stopPropagation();
    let newItem = new FText();
    newItem.text = "new item";
    newItem.icon = "fa fa-home";
    this.group.children.push(newItem);
    this.selected.emit(newItem);
  }

  onAddBefore(element: FBase){
    console.info("add before")
    let i = this.group.children.indexOf(element);
    let newItem = new FText();
    this.group.children.splice(i, 0, newItem);
    this.selected.emit(newItem);

  }

  onAddAfter(element: FBase){
    console.info("add after")
    let i = this.group.children.indexOf(element);
    let newItem = new FText();
    newItem.text = "new item";
    this.group.children.splice(i+1, 0, newItem);
    this.selected.emit(newItem);
  }

  onDelete(element: FBase){
    console.info("delete")
    let i = this.group.children.indexOf(element);
    this.group.children.splice(i, 1);
    this.selected.emit(null);
  }

  onMoveUp(element: FBase){
    let i = this.group.children.indexOf(element);
    console.debug("index="+i);
    if(i>0)
    {
      this.group.children.splice(i, 1);
      this.group.children.splice(i-1, 0, element);
    }
  }

  onMoveDown(element: FBase){
    let i = this.group.children.indexOf(element);
    if(i<this.group.children.length - 1)
    {
      this.group.children.splice(i, 1);
      this.group.children.splice(i+1, 0, element);
    }
  }
}
