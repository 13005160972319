<div (click)="onClick($event, group)"
  style="margin-bottom: 10px;"
  [class.group-border]="group.text"
  [class.group]="group.text == ''">
  <div *ngIf="group.text == ''" class="group-header">
    <div style="font-style: italic; color: gray;">Click to select group</div>
  </div>
  <div *ngIf="group.text" class="group-header"
    [(style.background)]="group.background"
    [(style.color)]="group.foreground">
    <i *ngIf="group.icon" class="{{group.icon}}" class="space-right" style="font-size:large;"></i>
    <div>{{group.text}}</div>
  </div>
  <div class="column">
    <div *ngIf="group.children.length == 0" (click)="onAdd($event)" class="element-new">
      <i class="fa fa-plus"></i>
    </div>

    <app-form-base *ngFor="let i of group.children" class="groupBody" [element]="i" [row]="true"
      (selected)="onSelected($event)" (addBefore)="onAddBefore($event)" (addAfter)="onAddAfter($event)"
      (delete)="onDelete($event)" (moveUp)="onMoveUp($event)" (moveDown)="onMoveDown($event)"></app-form-base>
  </div>
</div>
