import { Component, Input, OnInit } from '@angular/core';
import { RuleAnd, RuleValue } from '../editor-rule/editor-rule.component';

@Component({
  selector: 'app-rule-value',
  templateUrl: './rule-value.component.html',
  styleUrls: ['./rule-value.component.css']
})
export class RuleValueComponent implements OnInit {
  @Input() element: RuleValue;

  constructor() { }

  ngOnInit(): void {
  }

}
