<div
  style="display: flex; flex-direction: row; justify-content: flex-start; padding: 5px; justify-content: space-between;">
  <div style="flex-direction: row;">
    <i *ngIf="element.icon" class="{{element.icon}}"
      style="margin-right: 5px; font-size:large;  align-self: center;"></i>
    <label style="font-size: medium;">{{element.text}}</label>
  </div>
  <i *ngIf="element.info" class="fa fa-info-circle"
    style="margin-right: 5px; font-size:large;  align-self: center;"></i>

</div>
