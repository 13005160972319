import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-tab-button',
  templateUrl: './editor-tab-button.component.html',
  styleUrls: ['./editor-tab-button.component.css']
})
export class EditorTabButtonComponent implements OnInit {
  @Input('s') select: boolean;
  @Input('icon') Icon: string;
  @Input('text') Text: string;
  @Input('count') Count: number;


  constructor() { }

  ngOnInit(): void {
  }

}
