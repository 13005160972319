<div class="row" (click)="onClick()">
  <div class="column" style="padding-top: 15px; padding-bottom: 15px; z-index: 1;">
    <app-editor-tab-button *ngFor="let tab of tabs" [s]="selected === tab.text" [icon]="tab.icon" [text]="tab.text"
      [count]="tab.count" (click)="onSelect(tab.text)"></app-editor-tab-button>

  </div>
  <div style="border-color: darkblue;
  border-width: 2px;
              border-style: solid;
              min-width: 200px;
              border-radius: 10px;
              margin-left: -2px;
              padding: 10px;">

    <div [ngSwitch]="selected">
      <app-editor-basic *ngSwitchCase="'Basic'" [element]="element"></app-editor-basic>
      <app-editor-options *ngSwitchCase="'Options'" [element]="element"></app-editor-options>
      <app-editor-links *ngSwitchCase="'Links'" [element]="element"></app-editor-links>
      <app-editor-coding *ngSwitchCase="'Encoding'" [element]="element"></app-editor-coding>
      <app-editor-access *ngSwitchCase="'Access'" [element]="element"></app-editor-access>
      <app-editor-rule *ngSwitchCase="'Rule'" [element]="element"></app-editor-rule>
    </div>
  </div>
