import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FBase, FColumns } from '../form';

@Component({
  selector: 'app-form-columns',
  templateUrl: './form-columns.component.html',
  styleUrls: ['./form-columns.component.css']
})
export class FormColumnsComponent implements OnInit {
  @Input() element: FColumns;
  @Output() selected = new EventEmitter<FBase>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelected(element: FBase){
    this.selected.emit(element);
  }

  onClick(event: MouseEvent){
    event.stopPropagation();
    this.selected.emit(this.element);
  }



}
